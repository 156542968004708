html{
  background: linear-gradient(-70deg, rgb(32, 137, 202) 20%, rgb(76, 164, 219) 50%,rgb(32, 137, 202) 80%);
}
.App {
  text-align: center;
}

.App .menu{
  background: rgb(9, 84, 130);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.vallensbaek .news-slider{
  height: calc(9 * 3vh);
  position: absolute;
  width: 34vw;
  right: 2vh;
  bottom: 3vh;
  overflow: hidden;
  border-radius: .5vh;
  box-shadow: 0 0 4px 2px rgba(0,0,0,.1);
}

.vallensbaek .news-slider .news-slide-dr{
  height: calc(9 * 3vh);
}

.slider-1{
  position: absolute;
  bottom: 3vh;
  left: 7vw;
  height: calc(9 * 3vh);
  width: calc(16 * 3vh);
}

.slider-2{
  position: absolute;
  bottom: 3vh;
  left: calc(16 * 3vh + 9vw);
  height: calc(9 * 3vh);
  width: calc(16 * 3vh);
}

.slider-1 img, .slider-2 img{
  width: 100%;
  height: 100%;
}

.vallensbaek .free-text-item{
  position: absolute;
  bottom: 3vh;
  left: 7vw;
  height: calc(9 * 3vh);
  width: calc(16 * 3vh);
}

.free-text{
  background: white;
  width: 100%;
  height: 100%;
}

.free-text .text{
  color: black;
  text-align: left;
  padding: 1vh;
  white-space: pre-wrap;
}

.flatpickr-calendar{
  transform-origin: top right;
  transform: scale(2);
}