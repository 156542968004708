.news-slide{
    position: absolute;
    width: 17.9vw;
    right: 2.3vw;
    bottom: 3vw;
    overflow: hidden;
}

.news-slide-dr{
    position: relative;
    box-sizing: border-box;
    background: rgba(255,255,255,0.3);
    color: black;
    padding: 1vh;
    height: 100%
}

.news-slide-dr h3{
    float: left;
    text-align: left;
    padding: 0;
    font-size: 2.2vh;
    line-height: 2.2vh;
    height: 6vh;
    margin: 0 0 1vh;
    width: 65%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.news-slide-dr p{
    float: left;
    font-size: 2vh;
    line-height: 2vh;
    max-width: 50%;
    box-sizing: border-box;
    margin: 0;
    padding: 0 1vh 0;
    text-align: left;
}

.news-slide-dr p.full-width{
    max-width: 100%;
    padding: 0;
}

.news-slide-dr p a{
    text-decoration: none;
}

.news-slide-dr .article-img{
    max-width: 50%;
    max-height: 70%;
    float: left;
}

.news-slide-dr .logo {
    position: absolute;
    width: 35%;
    top: 0;
    right: 0;
    padding: 1vh;
    box-sizing: border-box;
}